
import {Options, Vue} from "vue-class-component";

@Options({
  components: {

  },
  props: {
    keywords: {
      required: true
    }
  }
})
export default class FanzoneTitle extends Vue {
}
