import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tabs-subtitle level-bg d-flex flex-justify-center flex-items-center" }
const _hoisted_2 = { class: "one-line-subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, (keyword) => {
        return (_openBlock(), _createElementBlock("span", {
          key: keyword.keyword
        }, " #" + _toDisplayString(keyword.keyword.replace('#', '')), 1))
      }), 128))
    ])
  ]))
}